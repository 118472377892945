<template>
  <div id="divPlainPackageWrapper" class="pp-container">
    <div class="content-layout-fixer">
      <div class="content-row slide-wrap">
        <BrandContentSlider
          v-if="contents"
          :slides="contents"
          :config="config"
          logger="plainPackage"
        ></BrandContentSlider>
      </div>
    </div>
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="hints-container">
          <VueText sizeLevel="9" weightLevel="2" class="hints-title"
            >İşinizi Kolaylaştıracak İpuçları</VueText
          >
          <div class="scrolling-wrapper">
            <div v-for="slide in bannersToShow" class="card" :key="slide.title">
              <img :src="slide.image" alt="" @click="goTo(slide.link)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <div class="pp-link-container">
          <VueButton
            :size="sizes.xLarge"
            class="pp-button-link"
            :target="routes.regulationDetails"
            as="router-link"
            iconColor="#FFFFFF"
            :iconName="icon.gavel.name"
            :iconSize="{
              width: icon.gavel.width,
              height: icon.gavel.height,
            }"
          >
            <VueText :isSingleLine="true" color="white-100" sizeLevel="4" weightLevel="4">
              Regülasyon Detayları
            </VueText>
          </VueButton>
          <VueButton
            :size="sizes.xLarge"
            class="pp-button-link"
            :target="routes.healthWarnings"
            as="router-link"
            iconColor="#FFFFFF"
            :iconName="icon.earth.name"
            :iconSize="{
              width: icon.earth.width,
              height: icon.earth.height,
            }"
          >
            <VueText :isSingleLine="true" color="white-100" sizeLevel="4" weightLevel="4">
              Güncel Sağlık Uyarıları
            </VueText>
          </VueButton>
        </div>
      </div>
    </div>
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="pp-brand-container">
          <div class="head-brand">
            <img src="~@/assets/images/plainpackage.png" alt="head-brand" />
          </div>
          <div class="brand-grid">
            <router-link
              :to="`brand/${product.brandId}`"
              v-for="product in brands"
              :key="product.brandId"
            >
              <img :src="product.image" alt="brand-image" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <div class="pp-footer-container">
          <BrandButton
            :outlined="true"
            :size="sizes.xLarge"
            :target="routes.faq"
            as="router-link"
            :iconName="icon.info.name"
            :iconSize="{ width: icon.info.width, height: icon.info.height }"
            >Sıkça Sorulan Sorular</BrandButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesStatic from '@/router/routes/secure/RoutesStatic';
import Content from '@/services/Api/content';
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider';

export default {
  name: 'PlainPackage',
  components: {
    VueText,
    VueButton,
    BrandButton,
    BrandContentSlider,
  },
  data() {
    return {
      contents: null,
      banners: [],
      brands: [],
      config: null,
      staticRoute: `${RoutesRoot.Secure.path}/${RoutesSecure.Statics.path}/`,
      imageSliderOption: {
        slidesPerView: 1,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    goTo(link) {
      if (link) {
        window.location = link;
      }
    },
  },
  beforeMount() {
    Content.getPlainPackage().then(res => {
      if (res && res.data) {
        const {
          Data: { banners, contents, brands, config },
        } = res.data;

        this.banners = banners;
        this.contents = contents;
        this.brands = brands;
        this.config = config;
      }
    });
  },
  computed: {
    icon() {
      return {
        right: ICON_VARIABLES['right'],
        gavel: ICON_VARIABLES['gavel'],
        earth: ICON_VARIABLES['earth'],
        info: ICON_VARIABLES['info'],
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    routes() {
      return {
        regulationDetails: this.staticRoute + RoutesStatic.RegulationDetails.path,
        healthWarnings: this.staticRoute + RoutesStatic.HealthWarnings.path,
        faq: this.staticRoute + RoutesStatic.FrequentlyAskedQuestions.path,
      };
    },
    media() {
      return {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
    },
  },
};
</script>
<style scoped lang="scss">
@mixin container-spacing {
  margin-top: palette-space-level(30);
  padding-left: palette-space-level(20);
  padding-right: palette-space-level(20);
  margin-bottom: palette-space-level(30);
}
.content-row {
  height: unset;
  .swiper-container {
    height: 344px;
  }
}
.slide-wrap {
  width: 100%;
  max-width: 1098px;
}
.gray-bg {
  background: #f8f9f9;
  padding: palette-space-level(60) 0;
}
.pp-container {
  .pp-footer-container {
    max-width: 322px;
    margin: palette-space-level(60) auto;
  }
  margin-bottom: palette-space-level(40);
  .pp-link-container {
    @include container-spacing;
    display: flex;
    justify-content: center;
    .pp-button-link {
      border-radius: 4px;
      border: none;
      background-image: linear-gradient(to left, #d20051, #ffa300);
      height: 60px;
      line-height: 60px;
      display: flex;
      border-radius: 30px;
      align-items: center;
      justify-content: center;
      max-width: 270px;
      margin-right: 30px;
      .link-text {
        padding-left: palette-space-level(20);
      }
      .icon-right {
        margin-left: auto;
      }
      .pp-button-content {
        display: flex;
        align-items: center;
      }
    }
  }
  .pp-brand-container {
    @include container-spacing;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    .head-brand {
      width: 152px;
      margin-right: palette-space-level(10);
      img {
        object-fit: contain;

        width: 100%;
      }
    }
    .brand-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      text-align: right;
      grid-column-gap: 21px;
      grid-row-gap: 40px;
      margin-left: palette-space-level(10);
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        border-radius: 6px;
        background: #fff;
        border: solid 1px #b4c2d3;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);

        img {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .pp-grid-container {
    @include container-spacing;

    .grid-col-main {
      img {
        object-fit: contain;
        height: 234px;
        width: 136px;
      }
    }
    .grid-col-sub {
      text-align: right;
      img {
        border-radius: palette-space-level(5);
        width: 80px;
        height: 80px;
        object-fit: contain;
      }
    }
  }
  .hints-container {
    padding-left: palette-space-level(20);
    .scrolling-wrapper {
      display: flex;
      flex-wrap: wrap;
      .card {
        flex: 0 0 auto;
        margin-right: palette-space-level(20);
        margin-top: palette-space-level(20);
        width: 230px;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }
    }
    .btn-more {
      display: block;
      margin: palette-space-level(30) auto 0 auto;
      border: 1px solid palette-color-level('grey', '20');
      align-self: center;
      background-color: palette-color-level('white', '100');
      border-radius: palette-radius-level(15);
      padding: palette-space-level(20) palette-space-level(50);
      cursor: pointer;
    }
  }
  .height-35 {
    background: palette-color(white-100);
    height: 35px;
  }
}
</style>
